import React from 'react';
import get from 'lodash.get';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube';
import ProductSliderBig from 'blocks/ProductSliderBig';
import { injectIntl, Link } from 'gatsby-plugin-react-intl';

import {
    Spacing,
    SharedStyles,
    Container,
    ProductStyle,
    ImageTextInfo,
    VideoBlock,
    StyleBlock,
    ImageTextGrid,
} from '../kenra-storybook/index';

const { StSectionInfo, StLinkMore, StTitle } = SharedStyles;

const PlatinumLanding = ({ intl, page }) => {
    let video1id = getYouTubeId(page.video1Url);
    let video2id = getYouTubeId(page.video2Url);

    return (
        <>
            <Spacing removeSpaceTop>
                <VideoBlock
                    renderVideo
                    imgUrl={get(page, 'videoPreview.localFile.publicURL')}
                >
                    <video preload="metadata">
                        <source
                            src={get(page, 'video.localFile.publicURL')}
                            type="video/mp4"
                        />
                        {intl.formatMessage({
                            id: 'kenra-video-not-supported',
                        })}
                    </video>
                </VideoBlock>
                <StyleBlock
                    title={page.title}
                    // subTitle={page.subtitle}
                    linkText={intl.formatMessage({
                        id: 'kenra-shop-collection',
                    })}
                    linkHref="/collection/kenra-platinum"
                    linkMobileText={intl.formatMessage({
                        id: 'kenra-mobile-watch-more',
                    })}
                    linkMobileMore="/video"
                    items={[
                        {
                            title: page.video1Title,
                            text: page.video1Text,
                            btnSizeMedium: true,
                            bgImg: getPreviewUrl(video1id),
                            videoType: 'youtube',
                            autoPlay: true,
                            allow:
                                'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
                            videoOptions: [
                                {
                                    src: getEmbedUrl(video1id),
                                },
                            ],
                        },
                        {
                            title: page.video2Title,
                            text: page.video2Text,
                            btnSizeMedium: true,
                            bgImg: getPreviewUrl(video2id),
                            videoType: 'youtube',
                            autoPlay: true,
                            allow:
                                'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
                            videoOptions: [
                                {
                                    src: getEmbedUrl(video2id),
                                },
                            ],
                        },
                    ]}
                />
            </Spacing>

            <Spacing removeSpaceBottomMobile>
                <StSectionInfo useMobileBg>
                    <Container>
                        <StTitle>
                            <h2>{page.contentTitle}</h2>
                        </StTitle>

                        {documentToReactComponents(JSON.parse(get(page, 'content.raw') || '{}' ))}

                        <StLinkMore styleBig>
                            <Link to="/collection/kenra-platinum">
                                {intl.formatMessage({ id: 'product-view-all' })}
                            </Link>
                        </StLinkMore>
                    </Container>
                </StSectionInfo>
            </Spacing>

            <Spacing removeSpaceTopMobile>
                <ProductStyle
                    items={[
                        {
                            bg: get(page, 'leftImage.localFile.publicURL'),
                            title: page.leftImageTitle,
                            text: page.leftImageText,
                            linkMoreText: intl.formatMessage({
                                id: 'view-all-text',
                            }),
                            linkMoreHref: page.leftImageUrl,
                        },
                        {
                            bg: get(page, 'rightImage.localFile.publicURL'),
                            title: page.rightImageTitle,
                            text: page.rightImageText,
                            linkMoreText: intl.formatMessage({
                                id: 'view-all-text',
                            }),
                            linkMoreHref: page.rightImageUrl,
                        },
                    ]}
                />
            </Spacing>

            <Spacing style={{ paddingBottom: '100px' }}>
                <ImageTextGrid
                    items={[
                        {
                            img: get(page, 'bottomImage1.localFile.publicURL'),
                            title: page.bottomTitle1,
                            text: page.bottomText1,
                            linkText: page.bottomButtonTitle1,
                            linkHref: page.bottomButtonLink1,
                            linkStyleUnderline: true,
                        },
                        {
                            img: get(page, 'bottomImage2.localFile.publicURL'),
                            title: page.bottomTitle2,
                            text: page.bottomText2,
                            linkText: page.bottomButtonTitle2,
                            linkHref: page.bottomButtonLink2,
                            linkStyleUnderline: true,
                        },
                        {
                            img: get(page, 'bottomImage3.localFile.publicURL'),
                            title: page.bottomTitle3,
                            text: page.bottomText3,
                            linkText: page.bottomButtonTitle3,
                            linkHref: page.bottomButtonLink3,
                            linkStyleUnderline: true,
                        },
                    ]}
                />
            </Spacing>

            {/* <Spacing>
        <ProductSliderBig
          title="Stylist favorites"
          collectionHandle={get(page, 'stylistFavorites.handle')}
          viewAllLink={get(page, 'stylistFavoritesViewAllLink')}
        />
      </Spacing> */}
        </>
    );
};

export default injectIntl(PlatinumLanding);

import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import PlatinumLanding from 'components/PlatinumLanding';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function KenraPlatinumLandingPage(props) {
    let {
        data: { contentfulPlatinumLanding },
    } = props;

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulPlatinumLanding,
                    'seoListing.description.description'
                )}
                title={get(contentfulPlatinumLanding, 'seoListing.seoTitle')}
                slug={'kenra-platinum'}
            />
            <PlatinumLanding page={contentfulPlatinumLanding} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        contentfulPlatinumLanding(node_locale: { eq: $locale }) {
            id
            video2Url
            video2Title
            video2Text
            video1Url
            video1Title
            video1Text
            subtitle
            title
            rightImageUrl
            rightImageTitle
            rightImageText
            rightImage {
                localFile {
                    publicURL
                }
            }
            leftImageUrl
            leftImageTitle
            leftImageText
            leftImage {
                localFile {
                    publicURL
                }
            }
            bottomTitle3
            bottomTitle2
            bottomTitle1
            bottomText3
            bottomText2
            bottomText1
            bottomButtonTitle1
            bottomButtonLink1
            bottomButtonTitle2
            bottomButtonLink2
            bottomButtonTitle3
            bottomButtonLink3
            bottomImage3 {
                localFile {
                    publicURL
                }
            }
            bottomImage2 {
                localFile {
                    publicURL
                }
            }
            bottomImage1 {
                localFile {
                    publicURL
                }
            }
            content {
                raw
            }
            contentTitle
            video {
                localFile {
                    publicURL
                }
            }
            videoPreview {
                localFile {
                    publicURL
                }
            }
            stylistFavorites {
                handle
            }
            stylistFavoritesViewAllLink
            node_locale
            seoListing {
                ...ContentfulSeoListingFields
            }
        }
    }
`;

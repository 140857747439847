import React, { Fragment } from 'react';
import get from 'lodash.get';
import { useStaticQuery, graphql } from 'gatsby';

import { getProductUrl } from 'helpers/url';
import { useIntl } from 'gatsby-plugin-react-intl';
import CustomLink from 'components/Link';

import {
    SharedStyles,
    Container,
    Slider,
    SliderFavorite2,
    SliderElms,
} from '../kenra-storybook/index';

const {
    StSectionTitle,
    StSectionMobileViewMore,
    StLink,
    StHoverOverlay,
} = SharedStyles;

const {
    StSliderFavorite2,
    StSliderFavorite2Desc,
    StSliderFavorite2Img,
} = SliderFavorite2;

const { StSliderLinkOverlay } = SliderElms;

export default function ProductSliderBig(props) {
    let {
        productHandles,
        collectionHandle,
        title,
        bigTitle,
        viewAllLink,
    } = props;

    let intl = useIntl();

    const {
        allShopifyCollection,
        allContentfulProduct,
    } = useStaticQuery(graphql`
        query {
            allShopifyCollection {
                nodes {
                    ...shopifyCollectionFields
                }
            }
            allContentfulProduct {
                nodes {
                    id
                    title
                    description
                    shopifyProduct {
                        handle
                    }
                    productItem {
                        title
                        images {
                            id
                            localFile {
                                publicURL
                                thumbnail: childImageSharp {
                                    resize(width: 100, height: 100) {
                                        src
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    if (collectionHandle) {
        let collection = allShopifyCollection.nodes.find(
            c => c.handle === collectionHandle
        );
        if (!collection) {
            console.error('ProductSlider: collection not found');
            return null;
        }

        productHandles = collection.products.map(p => p.handle);
    }
    if (!Array.isArray(productHandles) || productHandles.length === 0)
        return null;

    let products = productHandles.map(handle => {
        let contentfulProduct = allContentfulProduct.nodes.find(
            p => p.shopifyProduct.handle === handle
        );

        return {
            handle,
            contentfulProduct,
        };
    });

    let Wrapper = bigTitle ? StSectionMobileViewMore : Fragment;

    return (
        <Wrapper>
            {title && (
                <Container>
                    {bigTitle ? (
                        <StSectionTitle>
                            <h2>Latest Tutorials</h2>
                            {viewAllLink && (
                                <StLink>
                                    <CustomLink
                                        to={viewAllLink}
                                    >{`${intl.formatMessage({
                                        id: 'view-all-text',
                                    })} (${products.length})`}</CustomLink>
                                </StLink>
                            )}
                        </StSectionTitle>
                    ) : (
                        <StSectionTitle>
                            <h2>{title}</h2>
                            {viewAllLink && (
                                <StLink>
                                    <CustomLink
                                        href={viewAllLink}
                                    >{`${intl.formatMessage({
                                        id: 'view-all-text',
                                    })} (${products.length})`}</CustomLink>
                                </StLink>
                            )}
                        </StSectionTitle>
                    )}
                </Container>
            )}
            <Slider settings={{ slidesPerView: 'auto' }}>
                {products.map((product, index) => {
                    let { contentfulProduct, handle } = product;
                    let bigImage = get(
                        contentfulProduct,
                        'stylistFavoritesBigImage.localFile.publicURL'
                    );

                    return (
                        <StSliderFavorite2 key={`${product.id}_${index}`}>
                            {bigImage ? (
                                <img
                                    style={{ objectFit: 'cover', height: 690 }}
                                    src={bigImage}
                                    alt=""
                                />
                            ) : (
                                <div
                                    style={{
                                        height: 690,
                                        backgroundColor: '#4a4a4a',
                                    }}
                                />
                            )}

                            <StHoverOverlay>
                                <StSliderFavorite2Desc>
                                    <StSliderFavorite2Img>
                                        <img
                                            style={{
                                                objectFit: 'contain',
                                                maxHeight: 490,
                                            }}
                                            src={get(
                                                contentfulProduct,
                                                'productItem.images.0.localFile.publicURL'
                                            )}
                                            alt=""
                                        />
                                    </StSliderFavorite2Img>
                                    <h2>
                                        {get(
                                            contentfulProduct,
                                            'productItem.title'
                                        )}
                                    </h2>
                                    <h3>
                                        {get(contentfulProduct, 'description')}
                                    </h3>
                                    <StLink>
                                        <CustomLink
                                            href={getProductUrl(handle)}
                                        >
                                            {intl.formatMessage({
                                                id: 'kenra-shop-now',
                                            })}
                                        </CustomLink>
                                    </StLink>
                                </StSliderFavorite2Desc>
                                <StSliderLinkOverlay>
                                    <CustomLink href={getProductUrl(handle)}>
                                        {get(
                                            contentfulProduct,
                                            'productItem.title'
                                        )}
                                    </CustomLink>
                                </StSliderLinkOverlay>
                            </StHoverOverlay>
                        </StSliderFavorite2>
                    );
                })}
            </Slider>
        </Wrapper>
    );
}
